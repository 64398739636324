import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-smiley-rating-input',
    templateUrl: './smiley-rating-input.component.html',
    styleUrls: ['./smiley-rating-input.component.scss'],
    imports: [FormsModule, TranslateModule]
})
export class SmileyRatingInputComponent {
  @Input() set points(value: number) {
    this._points = value;
    this.updateRating();
  }
  get points(): number {
    return this._points;
  }

  @Input() maxPoints: number = 100; // Default value, update as needed
  @Output() pointsChange = new EventEmitter<number>();

  @Input() hideSlider: boolean = true;

  private _points: number = 0;
  rating: number = 0;
  isDisabled: boolean = false;
  componentId: string = Math.random().toString(36).substring(2);

  updateRating() {
    this.rating = Math.ceil((this.points / this.maxPoints) * 5);
  }

  updatePoints(newRating: number) {
    this.rating = newRating;
    this.points = Math.min(
      this.maxPoints,
      Math.ceil((this.maxPoints / 5) * this.rating),
    );
    this.pointsChange.emit(this.points);
  }

  updateFromSlider(event: Event) {
    this.points = +(event.target as HTMLInputElement).value;
    this.updateRating();
    this.pointsChange.emit(this.points);
  }

  updateFromButtons(points: number) {
    this.points = points;
    this.updateRating();
    this.pointsChange.emit(this.points);
  }

  increaseRating() {
    if (this.points < this.maxPoints) {
      this.updateFromButtons(this.points + 1);
    }
  }

  decreaseRating() {
    if (this.points > 1) {
      this.updateFromButtons(this.points - 1);
    }
  }
}
