import { ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { TraceService } from '@sentry/angular';

export function createTracingProviders(environment: any, appVersion: string) {
  const providers = [];
  providers.push(
    ...[
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: environment.sentry.showDialog,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      provideAppInitializer(() => {
        inject(TraceService);
      }),
    ],
  );

  return providers;
}
