import { Injectable } from '@angular/core';
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
  Storage,
} from '@angular/fire/storage';
import { FileUtils } from '../utils/file.utils';
import { GuidUtils } from '../utils/guid.utils';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular/standalone';

export interface FileUpload {
  filePath: string;
  file: File;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseStorageService {
  constructor(
    private storage: Storage,
    private platform: Platform,
  ) {}

  private async cacheFile(filePath: string, file: File): Promise<void> {
    const base64Data = await FileUtils.convertFileToBase64(file);
    await Filesystem.writeFile({
      path: this.getCacheFilePath(filePath),
      data: base64Data,
      directory: Directory.Cache,
    });
  }

  private async getCachedFile(filePath: string): Promise<File | null> {
    try {
      const cachedFilePath = this.getCacheFilePath(filePath);
      const fileExists = await Filesystem.stat({
        path: cachedFilePath,
        directory: Directory.Cache,
      });

      if (!fileExists || !fileExists.uri) {
        return null;
      }

      const readFile = await Filesystem.readFile({
        path: fileExists.uri,
      });

      let blob: Blob;
      if (typeof readFile.data === 'string') {
        // If data is a base64 string
        blob = FileUtils.convertBase64ToBlob(readFile.data);
      } else if (readFile.data instanceof Blob) {
        // If data is already a Blob
        blob = readFile.data;
      } else {
        throw new Error('Unexpected data type from Filesystem.readFile');
      }

      return new File([blob], FileUtils.getFileName(filePath), {
        type: blob.type,
      });
    } catch (error) {
      console.error('Error getting cached file:', error);
      return null;
    }
  }

  private getCacheFilePath(filePath: string): string {
    return `${FileUtils.getFileName(filePath)}`;
  }

  public async uploadFile(
    path: string,
    file: File,
    metadata?: Record<string, string>,
  ): Promise<FileUpload> {
    const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
    const filepath = `${normalizedPath}/${GuidUtils.generateUuid()}`;
    const storageRef = ref(this.storage, filepath);

    await uploadBytes(storageRef, file, metadata);
    await this.cacheFile(filepath, file).catch((error) => {
      console.error('Error caching file:', error);
    }); // TODO: Fix tha caching for videos https://claude.ai/chat/a11e51b2-d665-4aeb-b070-d1d80a5b7d96
    return {
      filePath: filepath,
      file: file,
    };
  }

  public async uploadBlob(
    firebasePath: string,
    blob: Blob,
    metadata?: Record<string, string>,
  ) {
    const file = FileUtils.convertBlobToFile(
      blob,
      `${GuidUtils.generateUuid()}`,
    );
    return this.uploadFile(firebasePath, file, metadata);
  }

  public async uploadPathBlob(
    firebasePath: string,
    pathBlob: string,
    metadata?: Record<string, string>,
  ): Promise<FileUpload> {
    const response = await fetch(pathBlob);
    const blob = await response.blob();
    const file = FileUtils.convertBlobToFile(
      blob,
      FileUtils.getFileName(pathBlob),
    );
    return this.uploadFile(firebasePath, file, metadata);
  }

  public async deleteFile(filePath: string): Promise<void> {
    const fileRef = ref(this.storage, filePath);
    await deleteObject(fileRef);
    await this.deleteCachedFile(filePath);
  }

  private async deleteCachedFile(filePath: string): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path: this.getCacheFilePath(filePath),
        directory: Directory.Cache,
      });
    } catch (error) {
      console.error('Error deleting cached file:', error);
    }
  }

  public async getFile(filePath: string): Promise<File> {
    const cachedFile = await this.getCachedFile(filePath);
    if (cachedFile) {
      return cachedFile;
    }

    const fileRef = ref(this.storage, filePath);
    try {
      const blob = await getBlob(fileRef);
      const metadata = await getMetadata(fileRef);
      const file = new File([blob], metadata.name, {
        type: metadata.contentType,
      });
      await this.cacheFile(filePath, file);
      return file;
    } catch (err) {
      console.error(err);
      return new File([], '');
    }
  }

  public async getDownloadUrl(filePath: string): Promise<string> {
    if (this.platform.is('hybrid')) {
      const cachedFile = await this.getCachedFile(filePath);
      if (cachedFile) {
        const base64Data = await FileUtils.convertFileToBase64(cachedFile);
        return `data:${cachedFile.type};base64,${base64Data}`;
      }
    }

    const currentFile = ref(this.storage, filePath);
    return await getDownloadURL(currentFile);
  }

  public async startDownload(
    fileName: string,
    filePath: string,
  ): Promise<void> {
    const file = await this.getFile(filePath);
    const url = window.URL.createObjectURL(file);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', fileName);
    downloadLink.click();
    downloadLink.remove();
  }

  public async copyFile(currentPath: string, newPath: string): Promise<any> {
    const file = await this.getFile(currentPath);
    const newFileRef = ref(this.storage, newPath);
    const uploadTask = uploadBytes(newFileRef, file);
    await this.cacheFile(newPath, file);
    return {
      uploadTask,
      fileRef: newFileRef,
      firebaseKey: newPath,
      file: file,
    };
  }
}
