import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'agoDate',
  standalone: true,
})
export class AgoFormatterPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value?: Date | null): string {
    if (!value) {
      return '';
    }
    const now = new Date();
    const diff = now.getTime() - value.getTime();

    // Convert difference to hours and minutes
    const diffHours = diff / (1000 * 60 * 60);
    const diffMinutes = diff / (1000 * 60);

    if (diffHours < 1) {
      // Less than 1 hour, show minutes
      return `${Math.floor(diffMinutes)} ${this.translateService.instant('common.agoFormatter.minutes')}`;
    } else if (diffHours < 24) {
      // Less than 24 hours, show time in hh:mm format
      return (
        value.getHours() + ':' + value.getMinutes().toString().padStart(2, '0')
      );
    } else {
      // More than 24 hours, show date in dd/mm format
      return (
        value.getDate().toString().padStart(2, '0') +
        '/' +
        (value.getMonth() + 1).toString().padStart(2, '0')
      );
    }
  }
}
