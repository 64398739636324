import { inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Actions, getActionTypeFromInstance } from '@ngxs/store';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class NgxsSentryBreadcrumbsService implements OnDestroy {
  #actions = inject(Actions);
  #destroyed$ = new Subject<void>();

  constructor() {
    this.#subscribeToActions();
  }

  #subscribeToActions() {
    this.#actions.subscribe((ctx) => {
      const actionType = getActionTypeFromInstance(ctx.action);
      if (actionType) {
        Sentry.addBreadcrumb({
          category: 'NGXS',
          message: `${actionType} ${ctx.status}`,
          level: 'info',
          type: 'info',
          data:
            typeof ctx.action === 'string' ? { data: ctx.action } : ctx.action,
        });
      }
    });
  }

  ngOnDestroy() {
    this.#destroyed$.next();
  }
}
