export abstract class ColorUtils {
  private static readonly colors: string[] = [
    '#FF4136',
    '#0074D9',
    '#2ECC40',
    '#FFDC00',
    '#B10DC9',
    '#FF851B',
    '#39CCCC',
    '#FF6AD5',
    '#8B4513',
    '#20B2AA',
    '#FFB900',
    '#006400',
    '#BA55D3',
    '#FF1493',
    '#1E90FF',
    '#008080',
    '#FFA07A',
    '#7B68EE',
    '#00FA9A',
    '#DC143C',
    '#4B0082',
    '#F0E68C',
    '#FF00FF',
    '#1ABC9C',
    '#95A5A6',
  ];

  public static generateRandomHslColor(): string {
    const randomInt = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const h = randomInt(0, 360);
    const s = randomInt(42, 98);
    const l = randomInt(40, 90);
    return `hsl(${h},${s}%,${l}%)`;
  }

  public static getColor(index: number = 0): string {
    if (index >= 0 && index < this.colors.length) {
      return this.colors[index];
    } else {
      return this.generateRandomHslColor();
    }
  }
}
