export function Throttle(delay: number = 2000): MethodDecorator {
  // @ts-ignore
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    let shouldWait = false;
    const original = descriptor.value;

    descriptor.value = function (...args: any) {
      if (!shouldWait) {
        original.apply(this, args);
        shouldWait = true;
        setTimeout(() => (shouldWait = false), delay);
      }
    };

    return descriptor;
  };
}
