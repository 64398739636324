import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ImageCacheService } from '../services/images-cache-service';

@Directive({
  selector: '[fSrc]',
  standalone: true,
})
export class FirebaseImageDirective implements OnInit {
  constructor(
    private element: ElementRef,
    private imageCache: ImageCacheService,
  ) {}

  @Input('fSrc') firebasePath?: string;

  ngOnInit() {
    if (this.firebasePath)
      this.imageCache
        .getImage(this.firebasePath)
        .then((imageUrl) => {
          this.element.nativeElement.src = imageUrl;
        })
        .catch((error) => {
          console.error('Failed to cache image:', error);
        });
  }
}
